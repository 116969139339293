<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.create_phase") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="card grid p-4">
      <custom-form
        :formElements="formElements"
        formStyle="inline"
        @submitForm="createPhase"
      />
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import CustomForm from "../components/form/CustomForm.vue";
import { getISODate } from "../utils/dateUtils";

export default {
  components: { Breadcrumb, CustomForm },
  setup() {
    const route = useRoute();
    const store = useStore();
    const { t } = useI18n();

    const company = computed(() => store.state.company.company);

    const formElements = ref([
      {
        class: "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
        element: "input",
        id: "name",
        label: "name",
        rules: ["isRequired"],
        type: "text",
        value: "",
        variable: "name",
      },
      {
        class: "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
        element: "datepicker",
        id: "startDate",
        label: "start_date",
        rules: ["isRequired"],
        textVariant: "white",
        value: getISODate(new Date()),
        variable: "startDate",
        variant: "indigo",
      },
      {
        class: "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
        element: "datepicker",
        id: "endDate",
        label: "end_date",
        rules: ["isRequired"],
        textVariant: "white",
        value: getISODate(new Date()),
        variable: "endDate",
        variant: "indigo",
      },
      {
        class: "col-span-12 text-right",
        element: "button",
        id: "submit-button",
        label: "save",
        textVariant: company.value.primaryText,
        type: "submit",
        variant: company.value.primary,
      },
    ]);

    const createPhase = (formData) => {
      const data = {
        name: formData.name,
        startDate: formData.startDate,
        endDate: formData.endDate,
        projectId: route.params.projectId,
      };
      store.dispatch("project/createPhase", {
        data,
        projectId: data.projectId,
      });
    };

    return {
      formElements,
      t,
      createPhase,
    };
  },
};
</script>
